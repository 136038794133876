<script setup lang="ts">
import {
  getAccountPermalink,
  getProfilePermalink,
  getAddressesPermalink,
  getPaymentPermalink,
  getOrdersPermalink,
} from '~/lib'

useHeadCommon()

const route = useRoute()
const to = route.query.to as string
const params = { to: to }

useAuthGuardRedirection(params)

// Navigation for Account page
const { logout, isLoggedIn } = useUser()
const router = useRouter()
const localePath = useLocalePath()

async function invokeLogout() {
  logout()
  router.push('/')
}
</script>
<template>
  <div class="layout layout-account">
    <slot name="header"> <Header /></slot>
    <main class="main">
      <div v-if="!isLoggedIn" class="authenticating">
        <h3>{{ $t('shop.accountPage.checkingCredentials') }}</h3>
      </div>
      <div v-else class="container grid">
        <section class="sidebar tl-3">
          <div class="wrapper">
            <h1 class="f-28">
              <NuxtLink :to="localePath(getAccountPermalink())">{{ $t('shop.accountPage.sideMenu.title') }}</NuxtLink>
            </h1>
            <h2 class="f-18">{{ $t('shop.accountPage.sideMenu.personalDetails') }}</h2>
            <ul class="cul">
              <li>
                <NuxtLink :to="localePath(getProfilePermalink())">
                  <Icon name="profile" />
                  {{ $t('shop.accountPage.sideMenu.myProfile') }}
                </NuxtLink>
              </li>
              <li>
                <NuxtLink :to="localePath(getAddressesPermalink())">
                  <Icon name="pin" />
                  {{ $t('shop.accountPage.sideMenu.myAddresses') }}
                </NuxtLink>
              </li>
            </ul>
            <h2 class="f-18">{{ $t('shop.accountPage.sideMenu.orderDetails') }}</h2>
            <ul class="cul">
              <li>
                <NuxtLink :to="localePath(getPaymentPermalink())">
                  <Icon name="pricelist" />
                  {{ $t('shop.accountPage.sideMenu.payment') }}
                </NuxtLink>
              </li>
              <li>
                <NuxtLink :to="localePath(getOrdersPermalink())">
                  <Icon name="offers" />
                  {{ $t('shop.accountPage.sideMenu.orderHistory') }}
                </NuxtLink>
              </li>
            </ul>
            <button @click="invokeLogout()">
              <Icon name="hide" />
              {{ $t('shop.accountPage.sideMenu.logout') }}
            </button>
          </div>
        </section>
        <section class="content tl-9">
          <slot />
        </section>
      </div>
    </main>
    <slot name="footer"> <Footer /> </slot>
  </div>
</template>
<style scoped lang="scss">
.sidebar {
  .wrapper {
    padding: 30px;
    background: var(--c-gray-12);
  }
  h2 {
    margin-bottom: 10px;
  }
  ul {
    margin-bottom: 20px;
  }
  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  button,
  a {
    &.router-link-active,
    &:hover {
      color: var(--c-primary);
    }
  }
}
.content {
  padding-top: 30px;
  margin-bottom: var(--p-mb);
}

.authenticating {
  min-height: 40vh;
  min-height: 40dvh;

  display: grid;
  place-content: center;
}
</style>
